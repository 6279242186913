import React from 'react';
import { Box, Center } from '@chakra-ui/react';
import Image from '@components/Common/LazyImage';

import { IMG_ICON_HAND } from '@/constants/assets';

function IconFooter() {
  return (
    <Box borderRadius="50%" width="80px" height="80px" bgColor="bgPrimary" boxShadow="md">
      <Center>
        <Image src={IMG_ICON_HAND} filter="invert(1)" height="80px" />
      </Center>
    </Box>
  );
}

export default IconFooter;
