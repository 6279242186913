import React from 'react';
import { Box, Center, Button, Text, useDisclosure, Heading, Image } from '@chakra-ui/react';

import { FaInstagram } from 'react-icons/fa';


import WithAnimation from '@components/Common/WithAnimation';
import ImageHover from '@components/Common/ImageHover';
import Content from './Content';
import { ENABLE_IG_FILTER } from '@/constants/feature-flags';
import txtWording from './locales';

import { IMG_PHOTO_1, IMG_PHOTO_2, IMG_PHOTO_3 } from '@/constants/assets';
import { DEFAULT_BUTTON_PROPS } from '@/constants/colors';

import useInvitation from '@hooks/useInvitation';
import { IG_FILTER, IMG_IG_FILTER } from '@/constants';
import useLang from '@hooks/useLang';

const IMAGES = [IMG_PHOTO_1, IMG_PHOTO_2, IMG_PHOTO_3];

function PhotoSection() {
  const defaultLang = useLang();
  const lang = defaultLang;
  
  const { isOpen, onClose, onOpen } = useDisclosure();

  const isInvitation = useInvitation();

  const handleClickFilterIG = () => {
    window.open(IG_FILTER, '_blank');
  };

  const renderText = (textProps = {}, boxProps = {}) => {
    return (
      <Box
        color="white"
        position="relative"
        left="4px"
        transform="translateY(40px)"
        zIndex={2}
        maxWidth="450px"
        width="95%"
        {...boxProps}
      >
        <Text
          fontWeight="bold"
          fontSize="2xl"
          textTransform="uppercase"
          letterSpacing="2px"
          textShadow="1px 1px 1px #BF843B"
          {...textProps}
        >
          LOVELOVELOVE
        </Text>
        <Text
          fontFamily="heading"
          fontSize="3xl"
          letterSpacing="4px"
          marginTop="-16px"
          paddingLeft="24px"
          textShadow="1px 1px 1px #BF843B"
          {...textProps}
        >
          Lovelovelove
        </Text>
      </Box>
    );
  };

  return (
    <Box marginTop="-24px" bgColor="bgPrimary" padding="0 0 32px 0">
      {renderText({ textAlign: 'left', paddingLeft: '4px' })}
      <Center>
        <Box marginTop="-32px">
          <ImageHover zIndex={1} images={IMAGES} imageProps={{ height: '250px', width: '250px' }} />
        </Box>
      </Center>
      {renderText({ textAlign: 'right', paddingRight: '0' }, { transform: 'translateY(-80px)' })}
      {/* Button Section */}
      {false &&
      <WithAnimation>
        <Center>
          <Button
            {...DEFAULT_BUTTON_PROPS}
            size="sm"
            fontWeight="light"
            bgColor="bgSecondary"
            marginTop="-24px"
            color="btnMainColor"
            // textTransform="uppercase"
            onClick={() => onOpen()}
          >
            {txtWording.btnGallery[lang]}
          </Button>
        </Center>
      </WithAnimation>
      }
      {/* Content gallery */}
      <Content isOpen={isOpen} onClose={onClose} />
      {isInvitation && ENABLE_IG_FILTER && (
        <Box>
          <WithAnimation>
            <Heading fontSize="4xl" textAlign="center" color="mainColorText" margin="44px 0 12px" fontFamily="heading" fontWeight="normal">
              Instagram <br />Wedding Filter
            </Heading>
          </WithAnimation>
          <WithAnimation>
            <Text align="center" color="mainColorText" marginBottom="16px" padding="0 44px 0 44px">
            {txtWording.descIGFilter[lang]}
            </Text>
          </WithAnimation>
          <WithAnimation>
            <Box margin="24px 0 24px">
              <Center>
                <Image src={IMG_IG_FILTER} maxW="70%" borderRadius="16px" boxShadow="lg" />
              </Center>
            </Box>
          </WithAnimation>
          <WithAnimation>
            <Center marginBottom="24px">
              <Button
                leftIcon={<FaInstagram />}
                size="sm"
                fontWeight="light"
                bgColor="bgSecondary"
                textColor="mainColorText"
                colorScheme="blackAlpha"
                // textTransform="uppercase"
                {...DEFAULT_BUTTON_PROPS}
                onClick={handleClickFilterIG}

              >
                {txtWording.btnFilter[lang]}
              </Button>
            </Center>
          </WithAnimation>
        </Box>
      )}
    </Box>
  );
}

export default React.memo(PhotoSection);
